.navigation {
    $self: &;
    position: fixed;
    z-index: 100;
    width: 100vw;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    padding: 2em 5em;
    transition: 0.6s;
  
    &__logo {
      align-self: flex-end;
      &-main {
        margin: 0;
        padding: 0;
        font-size: 2.6em;
        transition: 0.2s;
      }
      &-secondary {
        font-weight: 400;
        color: #2eb9f5;
      }
    }
  
    &__options {
      display: flex;
    }
  
    &__list {
      padding: 0;
      margin: 0;
      display: flex;
      align-self: flex-end;
      &-item {
        list-style: none;
        display: flex;
        a {
          align-items: flex-end;
          color: rgb(18, 19, 19);
          cursor: pointer;
          font-family: "Primer Print";
          font-size: 1.6em;
          font-weight: 1000;
          display: block;
          padding: 0 1em 0;
          box-sizing: border-box;
          border-bottom: 2px solid transparent;
          transition: 0.3s ease-in-out;
          &:hover {
            color: #131414;
          }
        }
      }
    }
    &--fixed {
      background-color: rgb(44, 44, 44);
      padding: 1em 5em;
      #{ $self }__logo {
        &-main {
          margin: 0;
          padding: 0;
          font-size: 2em;
          &-secondary {
            font-weight: 400;
            color: #2eb9f5;
          }
        }
      }
  
      #{ $self }__options {
        &__list {
          &-item {
            a {
              font-size: 1em;
            }
          }
        }
      }
    }
  
    &__hamburger {
      display: none;
    }
  }
  
  @media (max-width: 800px) {
    .navigation {
      $self: &;
      padding: 2em 1em;
  
      &__logo {
        z-index: 99;
        &-main {
          font-size: 1.5em;
        }
      }
      &__options {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 10em 5em;
        opacity: 0;
        transition: 0.5s ease-in-out;
        pointer-events: none;
        &--active {
          background-color: #202020;
          opacity: 1;
          pointer-events: auto;
        }
      }
      &__list {
        display: flex;
        flex-direction: column;
        &-item {
          width: 100%;
          a {
            color: #ffffffd3;
            font-weight: 400;
            padding: 0.3em 0;
            font-size: 1.2em;
            text-transform: uppercase;
            letter-spacing: 2px;
          }
        }
      }
  
      &--fixed {
        padding: 1.2em 1em;
        #{ $self }__options {
          &__list-item a {
            font-size: 1.2em;
          }
        }
        #{ $self }__logo {
          &-main {
            margin: 0;
            padding: 0;
            font-size: 1.2em;
          }
          &-secondary {
            font-weight: 400;
            color: #2eb9f5;
          }
        }
      }
  
      &__hamburger {
        align-self: center;
        display: block;
      }
    }
  }
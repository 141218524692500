

body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  background-color: white;
  color: black;
  font-size: 15px;
  scroll-behavior: smooth;
  width: 100vw;
  overflow-x: hidden !important;
  margin: 0;
  font-family: "Primer Print", BlinkMacSystemFont, "Primer Print";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {

  font-family: "Primer Print";
  src: url('./images/primer\ print.ttf');
}

a {
  list-style: none;
  text-decoration: none;
  color: inherit;
  font-family: "Primer Print"
}

h2 {
  color: black;
  font-family: "Primer Print"
}

h1 {
  color: black;
  font-weight: 300;
  font-family: "Primer Print"
  

}

p {
  font-size: 1.6em;
  line-height: 20px;
  color: black;
  font-family: "Primer Print"
  
}

.container {
  padding: 0 1.5em;
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Primer Print";
}

.menubgc {
  width: 100%;
  height: 8em;
  background-color: #2c2c2c;
  font-family: "Primer Print";
}

@media (max-width: 800px) {
  .menubgc {
    height: 5.56em;
    font-family: "Primer Print";
  }
}
/*# sourceMappingURL=App.css.map */

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  background-color: #fff;
  z-index: 999;
  overflow: hidden;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}


body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #131111;
  font-size: 15px;
  scroll-behavior: smooth;
  width: 100vw;
  overflow-x: hidden !important;
  margin: 0;
  font-family: "Primer Print";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Primer Print";

}

a {
  list-style: none;
  text-decoration: none;
  color: inherit;
  font-family: "Primer Print";
}

h6 {
  color: gray;
}

h2 {
  color: #92d7e4;
  font-family: "Primer Print";
}S

h4 {
  color: #abdbe3;
  font-family: "Primer Print";
}
h3 {
  color: black;
  font-family: "Primer Print";
}


h1 {
  color: gray;
  font-weight: 200;
  font-family: "Primer Print";
}

p {
  font-size: 1.6em;
  line-height: 20px;
  font-family: "Primer Print";
}

.container {
  padding: 0 1.5em;
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Primer Print";
}

.menubgc {
  width: 100%;
  height: 8em;
  background-color: #abdbe3;
  font-family: "Primer Print";
}

@media (max-width: 800px) {
  .menubgc {
    height: 5.56em;
    font-family: "Primer Print";
  }
}
/*# sourceMappingURL=App.css.map */

/* ==================== LOADING STYLING START ======================== */
.loader {
	margin: 0;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: transparent;
}

.loader .pulse {
	stroke-dasharray: 1840;
	stroke-dashoffset: 3680;
	animation: dash 2s ease-out infinite;
	stroke: url(#strokeGradient);
}

@keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}

.loader span {
	color: rgb(19, 18, 18);
	display: block;
	text-align: center;
	font-size: 35px;
	margin-left: 30px;
	font-family: "Cinzel Decorative", cursive;
	animation: blink 900ms linear infinite alternate;
}

@keyframes blink {
	from {
		opacity: 0;
	}
	to {
		opacity: 10;
	}
}

/* ==================== LOADING STYLING END ======================== */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  
  z-index: 999;
  overflow: hidden;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}


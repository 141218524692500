.error404 {
    background-image: url("/src/images/header2.jpg");
    height: 100vh;
    -webkit-filter: blur(10px);
            filter: blur(10px);
  }
  
  .wrapper404 {
    position: relative;
  }
  
  .text404 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-weight: normal;
    color: #9e9e9e;
    font-size: 48px;
    text-align: center;
  }
  /*# sourceMappingURL=page404.css.map */